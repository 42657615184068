/** @format */

import { useStyles } from "./AddUserModalStyle";
import { useState, useEffect, useRef } from "react";
import ActionButton from "components/ActionButton/ActionButton";
import ActionInput from "components/ActionInput/ActionInput";
import MessageModal from "components/MessageModal/MessageModal";
import clsx from "clsx";
import { validatePhone } from "Utilities/validator";

interface AddUserModalProps {
  show: boolean;
  phoneAuthType: boolean
  onClose: () => void;
  action: (e: any) => void;
}
export default function AddUserModal({
  show,
  phoneAuthType,
  onClose,
  action,

}: AddUserModalProps) {
  const classes = useStyles();

  const [showStatus, setShowStatus] = useState(show);
  const [fullName, setFullName] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [passWord, setPassWord] = useState("");
  const [passWord2, setPassWord2] = useState("");

  const rootRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
    function handleClick(e: any) {
      if (rootRef && rootRef.current && contentRef && contentRef.current) {
        const root: any = rootRef.current;
        const content: any = contentRef.current;
        if (root.contains(e.target) && !content.contains(e.target)) {
          onClose();
        }
      }
    }
  }, [rootRef, contentRef, show]);

  useEffect(() => {
    setShowStatus(show);
  }, [show]);

  const [errMsg, setErrMsg] = useState<Array<String>>([]);

  const handleNext = () => {
    let inputProps: any = {
      // last_name: lastName,
      // first_name: firstName,
      phone: phone,
      full_name: fullName,
      business_email: businessEmail,
      //private_email: privateEmail,
      //password: passWord,
      //password2: passWord2,
    };

    let errs = [];

    if (inputProps.full_name.length == 0) {
      errs.push("氏名が入力されていません。");
    }


    if (phoneAuthType) {
      if (inputProps.phone.length == 0) {
        errs.push("電話番号が入力されていません。")
      } else {
        if (validatePhone(inputProps.phone)) {
          errs.push("電話番号の形式が正しくありません。")
        }
      }
    } else {
      if (inputProps.business_email.length == 0) {
        errs.push("会社メールアドレスが入力されていません。");
      } else {
        if (!/^[^@]+@[^@.]+.[^@]+$/.test(inputProps.business_email)) {
          errs.push("会社メールアドレスの形式が正しくありません。");
        }
      }
    }

    setErrMsg([]);
    if (errs.length > 0) {
      let temp = errs;
      setErrMsg(temp);
      setErrorMessageModalStatus(true);
    } else {
      action(inputProps);
    }
  };

  const [errorMessageModalStatus, setErrorMessageModalStatus] = useState(false);

  function handleErrorMessageModal() {
    setErrorMessageModalStatus(false);
  }

  return (
    <>
      <div
        className={showStatus ? classes.loaderWrapper : classes.displayNone}
        ref={rootRef}
      >
        <div className={classes.modalRoot} ref={contentRef}>
          <div className={classes.title}>利用者追加</div>
          <div className={classes.descContainer}>
            各項目に情報を入力し、追加ボタンを押してください。
          </div>
          <div className={classes.mainContainer}>
            <div className={classes.errorMessage}>
              {errMsg.length > 0 ? (
                <>
                  <div>入力エラー</div>
                  <ul>
                    {errMsg.map((item: any, key: any) => {
                      return <li key={key}>{item}</li>;
                    })}
                  </ul>
                </>
              ) : null}
            </div>
            <div className={classes.name}>
              <div className={classes.form}>
                <div className={classes.formTitle}>
                  氏名 <span className={classes.symbol}>*</span>
                </div>
                <ActionInput
                  value={fullName}
                  action={(e) => setFullName(e)}
                  placeholder="山田 太郎"
                  className={classes.formLarge}
                />
              </div>
            </div>
            {!phoneAuthType && <div className={classes.form}>
              <div className={classes.formTitle}>
                メールアドレス <span className={classes.symbol}>*</span>
              </div>
              <ActionInput
                value={businessEmail}
                action={(e) => setBusinessEmail(e)}
                placeholder="xxx@xxxx.xx.xx"
                className={classes.formLarge}
              />
            </div>}
            {phoneAuthType &&
              <div className={classes.form}>
                <div className={classes.formTitle}>電話番号<span className={classes.symbol}>*</span></div>
                <ActionInput
                  value={phone}
                  action={(e) => setPhone(e)}
                  placeholder='09012345678'
                  className={classes.formMiddle}
                />
              </div>}
            {/* <div className={classes.form}>
              <div className={classes.formTitle}>パスワード <span className={classes.symbol}>*</span></div>
              <ActionInput
                type='password'
                value={passWord}
                action={(e) => setPassWord(e)}
                placeholder='xxxxxxxx'
                className={classes.formShort}
              />
            </div>
            <div className={classes.form}>
              <div className={classes.formTitle}>パスワード（再入力） <span className={classes.symbol}>*</span></div>
              <ActionInput
                type='password'
                value={passWord2}
                action={(e) => setPassWord2(e)}
                placeholder='xxxxxxxx'
                className={classes.formShort}
              />
            </div> */}
          </div>
          <div className={classes.btnContainer}>
            <ActionButton
              content="キャンセル"
              className={classes.cancelBtn}
              action={onClose}
            />
            <ActionButton
              content="追加"
              type="dark"
              className={classes.confirmBtn}
              action={handleNext}
            />
          </div>
          <MessageModal
            title="入力エラー"
            description={`入力エラーがあります。画面上部に表示しているエラーメッセージをご確認ください。`}
            show={errorMessageModalStatus}
            onClose={() => setErrorMessageModalStatus(false)}
            action={handleErrorMessageModal}
          />
        </div>
      </div>
    </>
  );
}
