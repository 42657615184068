/** @format */

import { useStyles } from "./UploadFileModalStyle";
import { useState, useEffect, useRef } from "react";
import ActionButton from "components/ActionButton/ActionButton";
import csvSample from "assets/files/employee_import_template_v5.xlsx";
import csvTypePhone from "assets/files/employee_import_template_v3.xlsx";
import { validatePhone } from "Utilities/validator";
import { isEmpty } from "lodash";
import { RegistrationTimingType } from "types/registrationTimingType";
import { ApiScheduledUserRegistrations } from "Apis/ApiScheduledUserRegistrations";
import { ApiDiffUsers } from "Apis/ApiUsers";
import { getUserId } from "Utilities/appHelper";

interface UploadFileModalProps {
  show: boolean;
  phoneAuthType: boolean;
  onClose: () => void;
  action: (data: any) => void;
}
export default function UploadFileModal({
  show,
  phoneAuthType,
  onClose,
  action,
}: UploadFileModalProps) {
  const classes = useStyles();

  const [showStatus, setShowStatus] = useState(show);
  const [sheetData, setSheetData] = useState<any>([]);
  const [timing, setTiming] = useState<RegistrationTimingType>("now");

  const initialScheduledAt = new Date();
  initialScheduledAt.setMinutes(
    initialScheduledAt.getMinutes() + (60 - initialScheduledAt.getMinutes())
  );
  initialScheduledAt.setSeconds(0);
  initialScheduledAt.setMilliseconds(0);
  const [scheduledAt, setScheduledAt] = useState<Date>(initialScheduledAt);
  const [deleteUsers, setDeleteUsers] = useState<any>([]);

  const rootRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
      // ApiScheduledUserRegistrations()
      //   .then((res) => {
      //     setSchedules(res["result-object"]);
      //   })
      //   .catch((err) => {
      //     console.log("[ApiScheduledUserRegistrations] err:", err);
      //   });
    } else {
      document.body.style.overflow = "auto";
    }
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
    function handleClick(e: any) {
      if (rootRef && rootRef.current && contentRef && contentRef.current) {
        const root: any = rootRef.current;
        const content: any = contentRef.current;
        if (root.contains(e.target) && !content.contains(e.target)) {
          onClose();
        }
      }
    }
  }, [rootRef, contentRef, show]);

  useEffect(() => {
    setShowStatus(show);
  }, [show]);

  const onFileInputChange = async (e: any) => {
    const fileData = e.target.files[0];
    var result = await new Promise((resolve) => {
      var reader = new FileReader();
      reader.onload = (f) => {
        resolve(reader.result);
      };
      reader.onerror = function (e) {
        resolve(reader.result);
      };
      reader.readAsText(fileData);
    });
    if (result !== null) {
      const sheetData = parseCsv(result, ",");
      sheetData.splice(0, 1);
      setSheetData(sheetData);
      const employeeIds = sheetData.map((row: any) => row[1]);

      const payload = {
        userId: getUserId(),
        employeeIds: employeeIds,
      };
      ApiDiffUsers(payload)
        .then((res) => {
          const response = res["result-object"];
          setDeleteUsers(response);
        })
        .catch((e) => {
          console.log(e);
          setErrMsg(["API取得エラー"]);
        });
    } else {
      setErrMsg([
        "ファイルを読み込めませんでした。（ファイルを更新した場合は、一度キャンセルボタンを押してください）",
      ]);
    }
  };

  const [errMsg, setErrMsg] = useState<Array<String>>([]);

  const handleNext = async () => {
    const confirmStatus = window.confirm(
      "更新しますか？削除される従業員の確認をしてください"
    );
    if (confirmStatus === false) return;
    sheetData.slice(0, 1);
    let errs: any = [];

    // validation
    if (isEmpty(sheetData)) {
      errs.push("ファイルが選択されていません。");
    }
    if (errs.length > 0) {
    } else if (sheetData.length > 0) {
      sheetData.forEach(function (row: any, i: any) {
        var j = i + 2;
        if (row[0] === void 0 || row[0].length == 0) {
          errs.push(j + "行目 氏名が入力されていません。");
        }

        if (row[1] === void 0 || row[1].length == 0) {
          errs.push(j + "行目 従業員IDが入力されていません。");
        }
      });
    } else {
      errs.push("データがありません。");
    }

    setErrMsg([]);
    if (errs.length > 0) {
      let temp = errs;
      setErrMsg(temp);
    } else {
      action(sheetData);
    }
  };

  //--------------parseCsv----------
  function parseCsv(csvStr: any, delimiter: any) {
    var rowRegex = /(?:(?:"[^"]*")*[^\r\n"]*)+/g,
      colRegex = new RegExp('(?:(?:"[^"]*")*[^' + delimiter + '"]*)+', "g"),
      rows = [],
      row,
      cells,
      cell,
      rowMaches,
      colMaches;
    while ((rowMaches = rowRegex.exec(csvStr)) !== null) {
      if (rowMaches[0] !== "") {
        cells = [];
        row = rowMaches[0];
        //セルを切り出す
        while ((colMaches = colRegex.exec(row)) !== null) {
          cell = colMaches[0].replace(/^\s+|\s+$/g, "");
          if (cell.charAt(0) == '"' && cell.charAt(cell.length - 1) == '"') {
            cell = cell.slice(1, -1);
          }
          cell = cell.replace(/""/g, '"');
          cells.push(cell);
          colRegex.lastIndex++; //一歩前へ！
        }
        rows.push(cells);
      }
      rowRegex.lastIndex++; //一歩前へ！
    }
    return rows;
  }
  //--------------parseCsv----------

  const validateSchedule = (datetime: Date) => {
    const now = new Date();
    return datetime > now;
  };

  return (
    <>
      <div
        className={showStatus ? classes.loaderWrapper : classes.displayNone}
        ref={rootRef}
      >
        <div className={classes.modalRoot} ref={contentRef}>
          <h2 className={classes.title}>利用者の更新</h2>
          <div className={classes.descContainer}>
            <div className={classes.container}>
              <div className={classes.descDetail}>
                <h3 className={classes.descDetailTitle}>更新方法</h3>
                (1) テンプレートのxslxファイルをダウンロード
                <br />
                (2) Excelの2行目から従業員情報を登録
                <br />
                (3)
                Excelをcsvファイル（コンマ区切り）で保存して、ここへアップロード
                <br />
                <span className={classes.anotation}>
                  ※
                  CSVファイル(Shift-JIS形式)では文字化けしてしまうのでご注意ください。
                </span>
                <br />
                (4)
                削除される従業員が存在する場合は一覧が表示されますので、ご確認ください
              </div>
              <div className={classes.formContainer}>
                <div className={classes.mainContainerFlex}>
                  <div className={classes.mainContainer}>
                    <div className={classes.form}>
                      <div className={classes.formTitle}>
                        従業員情報をアップロードする
                      </div>
                      <input
                        type="file"
                        accept="text/csv"
                        onChange={(e) => onFileInputChange(e)}
                        className={classes.fileInput}
                      />
                    </div>
                  </div>
                  <div className={classes.mainContainer}>
                    <div className={classes.form}>
                      <div className={classes.formTitle}>テンプレート</div>
                      <div>
                        <a
                          href={phoneAuthType ? csvTypePhone : csvSample}
                          download="MOEベネフィット_従業員一括登録用.xlsx"
                          className={classes.smallLink}
                        >
                          ダウンロード
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className={classes.errorMessage}>
                    {errMsg.length > 0 ? (
                      <>
                        <div>入力エラー</div>
                        <ul>
                          {errMsg.map((item: any, key: any) => {
                            return <li key={key}>{item}</li>;
                          })}
                        </ul>
                      </>
                    ) : null}
                  </div>
                  <div className={classes.btnContainer}>
                    <ActionButton
                      content="キャンセル"
                      className={classes.cancelBtn}
                      action={onClose}
                    />
                    <ActionButton
                      content="更新する"
                      type="dark"
                      className={classes.confirmBtn}
                      action={handleNext}
                    />
                  </div>
                </div>
              </div>
              {deleteUsers.length > 0 && (
                <>
                  <div className={classes.deleteContainer}>
                    <div className={classes.deleteContainerTitle}>
                      削除対象従業員一覧
                    </div>
                    <div className={classes.scheduleHeader}>
                      <p className={classes.scheduleTitle}>従業員名</p>
                      <p className={classes.scheduleTitle}>職員番号</p>
                    </div>
                    <div className={classes.scheduleTable}>
                      {deleteUsers.map((user: any, index: number) => {
                        return (
                          <div
                            className={classes.scheduleRow}
                            key={"schedule" + index}
                          >
                            <div>{user.full_name}</div>
                            <div>{user.employee_id}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}

              {/* <div className={clsx(classes.descDetail, classes.beforeDownload)}>
              <strong>■ インポート用ファイル</strong><br />
              以下のファイルはCSVインポートのサンプルファイルです。ダウンロードしてご利用ください。
            </div>
            <div className={classes.downloadBig}><a href={csvSample} download>CSVインポートサンプル.csv</a></div>
            <div className={classes.downloadSmall}>
              <div className={classes.fileSize}>1KB・</div><div className={classes.smallLink}><a href={csvSample} download>Download</a></div>
            </div> */}
              {/* <div className={classes.descDetail}>
              <strong>■ 各列の入力内容</strong><br />
              左から、姓,名,電話番号（ハイフン有り）,会社メールアドレス,個人メールアドレス（空欄可）,パスワード
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
