import { FooterStyles } from "./FooterStyles";
import {
  COMPANY_ABOUT_URL,
  COMPANY_TERM_URL,
  COPYRIGHT,
  PRIVACY_POLICY_URL,
} from "config/constant";

export default function Footer() {
  const classes = FooterStyles();

  /* const handleCompanyAbout = () => {
    window.open(
      "https://corp.shisaku-pad.co.jp/company",
      "_blank"
    );
  };
  const handleCompanyPolicy = () => {
    window.open(
      "https://corp.shisaku-pad.co.jp/privacy-policy",
      "_blank"
    );
  };s
    window.open(
      "https://corp.shisaku-pad.co.jp/terms",
      "_blank"
    );
  }; */

  return (
    <div className={classes.root}>
      <a
        className={classes.link}
        href={COMPANY_ABOUT_URL}
        target="_blank"
        rel="noreferrer"
        //onClick={handleCompanyAbout}
      >
        運営会社
        <i className="far fa-external-link-alt"></i>
      </a>
      <a
        className={classes.link}
        href={PRIVACY_POLICY_URL}
        target="_blank"
        rel="noreferrer"
        //onClick={handleCompanyPolicy}
      >
        プライバシーポリシー
        <i className="far fa-external-link-alt"></i>
      </a>
      <a
        className={classes.link}
        href={COMPANY_TERM_URL}
        target="_blank"
        rel="noreferrer"
        //onClick={handleCompanyTerm}
      >
        ご利用規約
        <i className="far fa-external-link-alt"></i>
      </a>
      <div className={classes.imLink}>
        <p>{COPYRIGHT}</p>
      </div>
    </div>
  );
}
