/** @format */

import ActionTable from "components/Table/ActionTable";
import { useState, useEffect, useRef } from "react";
import { useStyles } from "./LoginReportStyles";
import {
  ADD_TIME,
  DEFAULT_PER_PAGE_NUM,
  FILTER_MODE_CONTAINS,
  FILTER_MODE_ENDS_WITH,
  FILTER_MODE_STARTS_WITH,
} from "config/constant";

import TableContent from "./Components/ReportContent/TableContent";
import ReportDetailModal from "./Components/ReportDetailModal/ReportDetailModal";
import { defaultFilterParameters } from "components/Table/TableBase/TableFilter";
import { CSVLink } from "react-csv";

import { ApiAccessLogs } from "Apis/ApiAccessLogs";

import { ApiServiceApplications } from "Apis/ApiServiceApplications";
import { ProcLoginHistorys } from "Utilities/ProcLoginHistorys";
import { ProcServiceApplications } from "Utilities/ProcServiceApplications";
import { isEmpty } from "lodash";
import { CircularProgress } from "@material-ui/core";

export const tableHeaderLoginHistyorys = ["No", "氏名", "利用日時", "利用端末"];

const filterColumnsLoginHistorys = ["利用日時", "利用端末"];

export const LoginReport = () => {
  const classes = useStyles();

  const defaultSortHeader = 0;
  const defaultReverseFlg = false;
  const [sortHeader, setSortHeader] = useState(defaultSortHeader);
  const [reverseFlg, setReverseFlg] = useState(defaultReverseFlg);

  const [filterParameters, setFilterParameters] = useState<any>(
    defaultFilterParameters
  );

  const [loginHistorysArray, setLoginHistorysArray] = useState<Array<any>>([]);
  const [loginHistorysArrayTmp, setLoginHistorysArrayTmp] = useState<
    Array<any>
  >([]);
  const [serviceApplicationsArray, setServiceApplicationsArray] = useState<
    Array<any>
  >([]);

  const [mixArray, setMixArray] = useState<Array<any>>([]);
  const [mixArrayTmp, setMixArrayTmp] = useState<Array<any>>([]);

  const [initFlg, setInitFlg] = useState(true);

  useEffect(() => {
    ApiAccessLogs(callBackApiAccessLogs(ProcLoginHistorys));
    ApiServiceApplications(
      callBackApiServiceApplications(ProcServiceApplications)
    );
  }, []);

  function callBackApiAccessLogs(func: any) {
    var list: any = [];
    return (data: any) => {
      data.sort(compare_create_at);
      data.reverse();
      func(data).forEach((item: any, key: any) => {
        item.no = key + 1;
        item.item_type = "LoginHistorys";
        item.create_at_display = mongodbTimeToDisplay(item.create_at);
        list.push(item);
      });
      setLoginHistorysArray(list);
    };
  }

  function callBackApiServiceApplications(func: any) {
    var list: any = [];
    return (data: any) => {
      data.sort(compare_application_datetime);
      data.reverse();
      func(data).forEach((item: any, key: any) => {
        item.no = key + 1;
        item.item_type = "ServiceApplications";
        item.application_datetime_display = mongodbTimeToDisplay(
          item.application_datetime
        );
        list.push(item);
      });
      setServiceApplicationsArray(list);
    };
  }

  function mongodbTimeToDisplay(strMongoTime: string) {
    if (!(strMongoTime === void 0)) {
      var year = Number(strMongoTime.slice(0, 4));
      var month = Number(strMongoTime.slice(5, 7)) - 1;
      var day = Number(strMongoTime.slice(8, 10));
      var hour = Number(strMongoTime.slice(11, 13));
      var minute = Number(strMongoTime.slice(14, 16));
      var seccond = Number(strMongoTime.slice(17, 19));
      var dt = new Date(year, month, day, hour, minute, seccond);

      dt.setHours(dt.getHours() + ADD_TIME);

      return dt.toLocaleString();
    } else {
      return "-";
    }
  }

  useEffect(() => {
    if (
      initFlg &&
      loginHistorysArray.length > 0 &&
      serviceApplicationsArray.length > 0
    ) {
      var list: any = [];
      var list2: any = [];
      loginHistorysArray.forEach((item: any) => {
        list.push({
          item_type: "MixLoginHistorys",
          item_type_output: "ログイン履歴",
          id: item.id,
          login_id_output: item.id,
          service_application_id_output: "",
          datetime: item.create_at,
          datetime_display: mongodbTimeToDisplay(item.create_at),
          user_id: item.user_id,
          user_name: item.user_name,
          user_name_display: item.user_name_display,
          business_email_display: item.business_email_display,
          client_id: item.client_id,
          user_enable: item.user_enable,
          user_enable_string: item.user_enable_string,
          at_that_time_client_id: item.at_that_time_client_id,
          login_type_display: item.login_type_display,
          require_auth_type_display: item.require_auth_type_display,
          service_id: "",
          service_title: "",
          service_title_display: "",
          at_that_time_user_name_display: "",
          at_that_time_service_title: "",
          at_that_time_service_title_display: "",
          at_that_time_email_type_display: "",
          at_that_time_application_email: "",
          devise: item.devise,
        });
      });

      list.sort(compare_datetime);
      list.reverse();
      list.forEach((item: any, key: any) => {
        item.no = key + 1;
        list2.push(item);
      });
      setMixArray(list2);
      setInitFlg(false);
    }
  }, [loginHistorysArray, serviceApplicationsArray]);

  //--------------report detail functin----------
  const [reportDetailShowStatus, setReportDetailShowStatus] = useState(false);
  const [reportInfoItem, setReportInfoItem] = useState<any>();

  const handleReportDetail = (e: any) => {
    setReportInfoItem(e);
    setReportDetailShowStatus(true);
  };

  //-----------pagination function start------------------------
  const defaultCurrentPage = 0;
  const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE_NUM);
  const handlePgNum = (pgNum: number) => {
    setCurrentPage(pgNum);
  };
  const handlePgRows = (rows: number) => {
    setPerPage(rows);
    setCurrentPage(0);
  };

  //-------------header sort function----------
  const handleHeader = (e: any) => {
    var workReverseFlg = false;
    if (e === sortHeader) {
      workReverseFlg = !reverseFlg;
    }
    setReverseFlg(workReverseFlg);

    setSortHeader(e);

    var list: any = [];

    loginHistorysArray.forEach((item: any) => {
      list.push(item);
    });

    switch (e) {
      case 0:
        list.sort(compare_no);
        break;
      case 1:
        list.sort(compare_create_at);
        break;
      case 2:
        list.sort(compare_user_name_display);
        break;
      case 3:
        list.sort(compare_business_email_display);
        break;
      case 4:
        list.sort(compare_login_type_display);
        break;
      case 5:
        list.sort(compare_require_auth_type_display);
        break;
      default:
    }

    if (workReverseFlg) {
      list.reverse();
    }

    setLoginHistorysArray(list);

    if (workReverseFlg) {
      list.reverse();
    }
    setMixArray(list);
  };

  function compare_no(a: any, b: any) {
    if (a.no < b.no) {
      return -1;
    }
    if (a.no > b.no) {
      return 1;
    }
    return 0;
  }

  function compare_create_at(a: any, b: any) {
    if (a.create_at < b.create_at) {
      return -1;
    }
    if (a.create_at > b.create_at) {
      return 1;
    }
    return 0;
  }

  function compare_application_datetime(a: any, b: any) {
    if (a.application_datetime < b.application_datetime) {
      return -1;
    }
    if (a.application_datetime > b.application_datetime) {
      return 1;
    }
    return 0;
  }

  function compare_datetime(a: any, b: any) {
    if (a.datetime < b.datetime) {
      return -1;
    }
    if (a.datetime > b.datetime) {
      return 1;
    }
    return 0;
  }

  function compare_user_name_display(a: any, b: any) {
    if (a.user_name_display < b.user_name_display) {
      return -1;
    }
    if (a.user_name_display > b.user_name_display) {
      return 1;
    }
    return 0;
  }

  function compare_business_email_display(a: any, b: any) {
    if (a.business_email_display < b.business_email_display) {
      return -1;
    }
    if (a.business_email_display > b.business_email_display) {
      return 1;
    }
    return 0;
  }

  function compare_login_type_display(a: any, b: any) {
    if (a.login_type_display < b.login_type_display) {
      return -1;
    }
    if (a.login_type_display > b.login_type_display) {
      return 1;
    }
    return 0;
  }

  function compare_require_auth_type_display(a: any, b: any) {
    if (a.require_auth_type_display < b.require_auth_type_display) {
      return -1;
    }
    if (a.require_auth_type_display > b.require_auth_type_display) {
      return 1;
    }
    return 0;
  }

  function compare_service_title(a: any, b: any) {
    if (a.service_title < b.service_title) {
      return -1;
    }
    if (a.service_title > b.service_title) {
      return 1;
    }
    return 0;
  }

  function compare_at_that_time_user_name_display(a: any, b: any) {
    if (a.at_that_time_user_name_display < b.at_that_time_user_name_display) {
      return -1;
    }
    if (a.at_that_time_user_name_display > b.at_that_time_user_name_display) {
      return 1;
    }
    return 0;
  }

  function compare_at_that_time_service_title(a: any, b: any) {
    if (a.at_that_time_service_title < b.at_that_time_service_title) {
      return -1;
    }
    if (a.at_that_time_service_title > b.at_that_time_service_title) {
      return 1;
    }
    return 0;
  }

  function compare_at_that_time_email_type_display(a: any, b: any) {
    if (a.at_that_time_email_type_display < b.at_that_time_email_type_display) {
      return -1;
    }
    if (a.at_that_time_email_type_display > b.at_that_time_email_type_display) {
      return 1;
    }
    return 0;
  }

  function compare_at_that_time_application_email(a: any, b: any) {
    const a_at_that_time_application_email =
      a.at_that_time_application_email !== null
        ? a.at_that_time_application_email
        : "";
    const b_at_that_time_application_email =
      b.at_that_time_application_email !== null
        ? b.at_that_time_application_email
        : "";

    if (
      a_at_that_time_application_email < b_at_that_time_application_email ||
      (a_at_that_time_application_email === "" &&
        b_at_that_time_application_email !== "")
    ) {
      return -1;
    }
    if (
      a_at_that_time_application_email > b_at_that_time_application_email ||
      (a_at_that_time_application_email !== "" &&
        b_at_that_time_application_email === "")
    ) {
      return 1;
    }
    return 0;
  }

  //-------------filter function----------
  const handleFilter = (
    filterParameters: any,
    filterChangeTrigger: boolean
  ) => {
    var list: any = [];
    var flg = false;

    loginHistorysArray.forEach((item: any) => {
      flg = false;

      if (filterParameters.filterText === "") {
        flg = true;
      } else {
        switch (filterParameters.filterColumn) {
          case 0:
            flg = filterMatchCheck(
              item.create_at_display,
              filterParameters.filterText,
              filterParameters.filterMode
            );
            break;
          case 1:
            flg = filterMatchCheck(
              item.devise,
              filterParameters.filterText,
              filterParameters.filterMode
            );
            break;
          default:
        }
      }

      if (flg) {
        list.push(item);
      }
    });

    setLoginHistorysArrayTmp(list);

    if (filterChangeTrigger) {
      setFilterParameters({
        ...filterParameters,
        filterColumn: filterParameters.filterColumn,
        filterMode: filterParameters.filterMode,
        filterText: filterParameters.filterText,
      });
    }
  };

  function filterMatchCheck(
    targetStr: string,
    searchStr: string,
    checkType: number
  ) {
    targetStr = targetStr?.toLowerCase();
    searchStr = searchStr?.toLowerCase();

    var result = false;

    switch (checkType) {
      case FILTER_MODE_CONTAINS:
        result = targetStr?.indexOf(searchStr) >= 0;
        break;
      case FILTER_MODE_STARTS_WITH:
        result = targetStr?.startsWith(searchStr);
        break;
      case FILTER_MODE_ENDS_WITH:
        result = targetStr?.endsWith(searchStr);
        break;
      default:
    }

    return result;
  }

  useEffect(() => {
    handleFilter(filterParameters, false);
  }, [loginHistorysArray]);

  //-------------csv output function----------
  const [outputCsvFetchDone, setOutputCsvFetchDone] = useState(false);
  const [csvOutputHeader, setCsvOutputHeader] = useState<Array<any>>([]);
  const [csvOutputdata, setCsvOutputdata] = useState<Array<any>>([]);
  const outputCsvFetchDoneRef = useRef<any>();

  function handleOutputCsv() {
    setCsvOutputHeader([
      { label: "利用日時", key: "create_at_display" },
      { label: "利用端末", key: "devise" },
    ]);
    setCsvOutputdata(loginHistorysArrayTmp);
    setOutputCsvFetchDone(true);
  }

  useEffect(() => {
    if (outputCsvFetchDone) {
      outputCsvFetchDoneRef.current.link.click();
      setOutputCsvFetchDone(false);
    }
  }, [outputCsvFetchDone]);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.reportTitle}>ログイン履歴</div>
        <div className={classes.reportSmallTitle}>
          以下より、ログイン履歴を確認できます。
        </div>
        <div className={classes.menuContainer}>
          <div className={classes.outputCsv} onClick={handleOutputCsv}>
            csv出力<i className="far fa-arrow-to-bottom"></i>
            {outputCsvFetchDone && (
              <CSVLink
                data={csvOutputdata}
                headers={csvOutputHeader}
                filename={"output.csv"}
                ref={outputCsvFetchDoneRef}
              />
            )}
          </div>
        </div>
        {isEmpty(loginHistorysArrayTmp) ? (
          <div className={classes.loading}>
            <CircularProgress size="1rem" color="inherit" />
          </div>
        ) : (
          <ActionTable
            className={classes.tableRoot}
            handlePgNum={handlePgNum}
            handlePgRows={handlePgRows}
            totalCnt={loginHistorysArrayTmp?.length}
            PgNum={currentPage}
            PgRows={perPage}
            maxPgRows={loginHistorysArrayTmp?.length}
            filterColumns={filterColumnsLoginHistorys}
            changeFilterParameters={(e) => handleFilter(e, true)}
            filterParameters={filterParameters}
            tableContent={
              <TableContent
                onRow={(e) => handleReportDetail(e)}
                rows={loginHistorysArrayTmp}
                pageNumber={currentPage}
                perPageNumber={perPage}
                columns={tableHeaderLoginHistyorys}
                sortHeader={sortHeader}
                reverseFlg={reverseFlg}
                headerSort={(e) => handleHeader(e)}
              />
            }
          />
        )}

        {/* <ReportDetailModal
          show={reportDetailShowStatus}
          reportInfo={reportInfoItem}
          onClose={() => setReportDetailShowStatus(false)}
        /> */}
      </div>
    </div>
  );
};
