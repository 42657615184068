import { getToken } from "Utilities/appHelper";
import { REQUEST_API_URL } from "../config/constant";

export async function ApiUsersDelete(user_id: any) {
    const token = getToken()
    const response: any = await fetch(REQUEST_API_URL + '/users/' + user_id, {
        method: 'DELETE',
        mode: 'cors',
        cache: 'no-store',
        credentials: 'include',
        headers: {
            Authorization: "Bearer " + token,
        },
        redirect: 'error',
        referrerPolicy: 'no-referrer-when-downgrade',
    })

    return response.json();
}
