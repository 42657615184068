/** @format */

import ActionButton from "components/ActionButton/ActionButton";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStyles } from "./EmployeeDetailStyles";

import { ApiUsersIdClient } from "Apis/ApiUsersIdClient";
import { ProcUsersId } from "Utilities/ProcUsersId";
import {
  ADD_TIME,
  EMPLOYEE_EDIT_LINK,
  USERS_AUTHORITIES_ADMIN_ADMIN,
  USERS_AUTHORITIES_CLIENT_ADMIN,
  USERS_AUTHORITIES_CLIENT_USER,
  USERS_AUTHORITIES_DISPLAY_ADMIN_ADMIN,
  USERS_AUTHORITIES_DISPLAY_CLIENT_ADMIN,
  USERS_AUTHORITIES_DISPLAY_CLIENT_USER,
  USERS_REGISTRATION_STATUS_DEFINITIVE,
  USERS_REGISTRATION_STATUS_DEFINITIVE_DISPLAY,
  USERS_REGISTRATION_STATUS_PROVISIONAL,
  USERS_REGISTRATION_STATUS_PROVISIONAL_DISPLAY,
} from "config/constant";
import { fullNameOrFamilyName, getClinetId } from "Utilities/appHelper";
import { ApiClientsId } from "Apis/ApiClientsId";
import { isEmpty } from "lodash";

export const EmployeeDetail = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { user_id } = useParams();
  const [userInfo, setUserInfo] = useState<any>();
  const [phoneAuthType, setPhoneAuthType] = useState<boolean>(false);


  /* const handleEdit = () => {
    navigate(`${EMPLOYEE_EDIT_LINK}/${user_id}`);
  }; */

  const init = async () => {
    if (userInfo == null) {
      ApiUsersIdClient(callBackApiUsersId(ProcUsersId), user_id);
    }
  };

  function callBackApiUsersId(func: any) {
    return (data: any) => {
      const res = func(data);
      const setUserInfoData = {
        id: res.id,
        last_name: res.last_name,
        first_name: res.first_name,
        full_name: res.full_name,
        phone: res.phone,
        business_email: res.business_email,
        private_email: res.private_email,
        authorities: res.authorities,
        authorities_display: setAuthoritiesDisplay(res.authorities),
        registration_status_display:
          res.registration_status === USERS_REGISTRATION_STATUS_DEFINITIVE
            ? USERS_REGISTRATION_STATUS_DEFINITIVE_DISPLAY
            : res.registration_status === USERS_REGISTRATION_STATUS_PROVISIONAL
              ? USERS_REGISTRATION_STATUS_PROVISIONAL_DISPLAY
              : "-",
        create_at: res.create_at,
        create_at_display: mongodbTimeToDisplay(res.create_at),
        enable_str: res.enable ? "有効" : "無効",
        employee_id: res.employee_id
      };

      setUserInfo(setUserInfoData);
    };
  }

  function setAuthoritiesDisplay(authorities: any) {
    var output: any[] = [];
    if (authorities.includes(USERS_AUTHORITIES_CLIENT_USER))
      output.push(USERS_AUTHORITIES_DISPLAY_CLIENT_USER);
    if (authorities.includes(USERS_AUTHORITIES_CLIENT_ADMIN))
      output.push(USERS_AUTHORITIES_DISPLAY_CLIENT_ADMIN);
    if (authorities.includes(USERS_AUTHORITIES_ADMIN_ADMIN))
      output.push(USERS_AUTHORITIES_DISPLAY_ADMIN_ADMIN);
    return output.join(" / ");
  }

  function mongodbTimeToDisplay(strMongoTime: string) {
    if (!(strMongoTime === void 0)) {
      var year = Number(strMongoTime.slice(0, 4));
      var month = Number(strMongoTime.slice(5, 7)) - 1;
      var day = Number(strMongoTime.slice(8, 10));
      var hour = Number(strMongoTime.slice(11, 13));
      var minute = Number(strMongoTime.slice(14, 16));
      var seccond = Number(strMongoTime.slice(17, 19));
      var dt = new Date(year, month, day, hour, minute, seccond);

      dt.setHours(dt.getHours() + ADD_TIME);

      return dt.toLocaleString();
    } else {
      return "-";
    }
  }

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.title}>従業員詳細情報</div>
          <div className={classes.mainContainer}>
            <div className={classes.form}>
              <div className={classes.formTitle}>氏名</div>
                {fullNameOrFamilyName(
                  userInfo?.full_name,
                  userInfo?.first_name,
                  userInfo?.last_name
                )}
            </div>

            {phoneAuthType ? (
              <div className={classes.form}>
                <div className={classes.formTitle}>電話番号</div>
                {userInfo?.phone}
              </div>
            ) : (<div className={classes.form}>
              <div className={classes.formTitle}>従業員ID</div>
              {userInfo?.employee_id}
            </div>)}

            <div className={classes.form}>
              <div className={classes.formTitle}>権限</div>
              {userInfo?.authorities_display}
            </div>
            <div className={classes.form}>
              <div className={classes.formTitle}>登録日時</div>
              {userInfo?.create_at_display}
            </div>
          </div>
          <ActionButton
            href={`${EMPLOYEE_EDIT_LINK}/${user_id}`}
            type="dark"
            content="従業員情報を編集する"
            className={classes.formBtn}
          //action={handleEdit}
          />
        </div>
      </div>
    </>
  );
};
