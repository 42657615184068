
import { useStyles } from "./DotLoadingStyle";
import Logo from "../../assets/images/logo-hz.png";

const DotLoading = () => {
  const classes = useStyles();

  return (
    <div className={classes.loadingOverlay}>
      <div className={classes.loadingContainer}>
        <div className={classes.loadingText}>読み込み中です</div>
        <img src={Logo} alt="Loading" className={classes.loadingImage} />
        <div className={classes.loadingDots}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default DotLoading;
