import ActionSelect from "components/ActionSelect/ActionSelect";
import ActionInput from "components/ActionInput/ActionInput";
import { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core";
import { FILTER_MODE_CONTAINS } from "config/constant";

export const defaultFilterParameters = {
  filterColumn: 0,
  filterMode: FILTER_MODE_CONTAINS,
  filterText: "",
};

interface TableFilterProps {
  filterColumns: any;
  changeFilterParameters: (e: any) => void;
  filterParameters: any;
}

const useStyles = makeStyles(() =>
  createStyles({
    filterContainer: {
      display: "flex",
      width: "700px",
      overflowX: "auto",
      margin: "5px 0",
    },
    filterSelectMode: {
      marginLeft: "6px",
      width: "100px",
      "& select": {
        color: "#666666",
        display: "inline-block",
        fontSize: "14px",
        height: "40px",
        padding: "5px 10px",
        width: "100px",
      },
      "& i": {
        top: 14,
      },
    },
    filterSelectColumn: {
      width: "30%",
      "& select": {
        color: "#666666",
        display: "inline-block",
        fontSize: "14px",
        height: "40px",
        padding: "5px 10px",
      },
      "& i": {
        top: 11,
      },
    },
    filterInput: {
      width: "30%",
      display: "inline-block",
      fontSize: "14px",
      height: "40px",
      padding: "5px 10px",
      margin: "2px 0",
    },
    filterDescription: {
      color: "#666666",
      fontSize: "12px",
      margin: "auto 6px",
    }
  })
);

function TableFilter({
  filterColumns,
  changeFilterParameters,
  filterParameters,
}: TableFilterProps) {

  const classes = useStyles();

  const [filterParametersUseState, setFilterParametersUseState] = useState<any>(defaultFilterParameters);

  function handleFilterColumn(e: any) {
    changeFilterParameters({
      ...filterParameters,
      filterColumn: Number(e.target.value),
    })
  }

  function handleFilterMode(e: any) {
    changeFilterParameters({
      ...filterParameters,
      filterMode: Number(e.target.value),
    })
  }

  function handleFilterText(e: any) {
    changeFilterParameters({
      ...filterParameters,
      filterText: e
    })
  }

  useEffect(() => {
    setFilterParametersUseState(filterParameters);
  }, [filterParameters]);

  return (
    <>
      <div className={classes.filterContainer}>
        <div className={classes.filterDescription}>列:</div>
        <ActionSelect
          className={classes.filterSelectColumn}
          items={filterColumns}
          select={(e) => handleFilterColumn(e)}
          defaultValue={defaultFilterParameters.filterColumn}
          value={filterParametersUseState.filterColumn}
        />
        <ActionSelect
          className={classes.filterSelectMode}
          items={["部分一致", "前方一致", "後方一致"]}
          select={(e) => handleFilterMode(e)}
          defaultValue={defaultFilterParameters.filterMode}
          value={filterParametersUseState.filterMode}
        />
        <div className={classes.filterDescription}>キーワード:</div>
        <ActionInput
          className={classes.filterInput}
          //defaultValue={""}
          action={(e) => handleFilterText(e)}
          autoComplete="off"
          value={filterParameters.filterText}
        />
      </div>
    </>
  );
}

export default TableFilter;
