import { useStyles } from "./LoadingStyle";
import { useState, useEffect, useRef } from "react";
import ActionButton from "components/ActionButton/ActionButton";
import React from "react";
import clsx from "clsx";
import { CircularProgress } from "@material-ui/core";

interface LoadingProps {
  show: boolean;
}
export default function Loading({
  show,
}: LoadingProps) {
  const classes = useStyles();

  const [showStatus, setShowStatus] = useState(show);

  const rootRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
    function handleClick(e: any) {
      if (rootRef && rootRef.current && contentRef && contentRef.current) {
        const root: any = rootRef.current;
        const content: any = contentRef.current;
        if (root.contains(e.target) && !content.contains(e.target)) {
          //onClose();
        }
      }
    }
  }, [rootRef, contentRef, show]);

  useEffect(() => {
    setShowStatus(show);
  }, [show]);

  return (
    <>
      <div
        className={showStatus ? classes.loaderWrapper : classes.displayNone}
        ref={rootRef}>
        <div className={classes.modalRoot} ref={contentRef}>
          <div className={classes.title}>処理中</div>
          <div className={classes.mainContainer}>
            {/* <div id="load">
                <div>G</div>
                <div>N</div>
                <div>I</div>
                <div>D</div>
                <div>A</div>
                <div>O</div>
                <div>L</div>
              </div> */}
            {/* <div className={classes.loader}>
              <div className={clsx(classes.inner,classes.one)}></div>
              <div className={clsx(classes.inner,classes.two)}></div>
              <div className={clsx(classes.inner,classes.three)}></div>
            </div> */}
            <CircularProgress disableShrink />
          </div>
        </div>
      </div>
    </>
  );
}
