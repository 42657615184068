/** @format */

import ActionTable from "components/Table/ActionTable";
import { useState, useEffect } from "react";
import TableContent from "./Components/EmployeeListContent/TableContent";
import AddUserModal from "./Components/AddUserModal/AddUserModal";
import UploadFileModal from "./Components/UploadFileModal/UploadFileModal";
import MessageModal from "components/MessageModal/MessageModal";
import { useStyles } from "./EmployeeListStyles";

import { ApiUsers, bulkDelete, truncateTable } from "Apis/ApiUsers";
import { ApiPhoneUser, ApiUser } from "Apis/ApiUser";
import { ProcUsers } from "Utilities/ProcUsers";

import {
  ADD_TIME,
  DEFAULT_PER_PAGE_NUM,
  EMPLOYEE_LIST_LINK,
  FILTER_MODE_CONTAINS,
  FILTER_MODE_ENDS_WITH,
  FILTER_MODE_STARTS_WITH,
  USERS_AUTHORITIES_ADMIN_ADMIN,
  USERS_AUTHORITIES_CLIENT_ADMIN,
  USERS_AUTHORITIES_CLIENT_GUEST,
  USERS_AUTHORITIES_CLIENT_USER,
  USERS_AUTHORITIES_SHORT_DISPLAY_ADMIN_ADMIN,
  USERS_AUTHORITIES_SHORT_DISPLAY_CLIENT_ADMIN,
  USERS_AUTHORITIES_SHORT_DISPLAY_CLIENT_GUEST,
  USERS_AUTHORITIES_SHORT_DISPLAY_CLIENT_USER,
  USERS_REGISTRATION_STATUS_DEFINITIVE,
  USERS_REGISTRATION_STATUS_DEFINITIVE_DISPLAY,
  USERS_REGISTRATION_STATUS_PROVISIONAL,
  USERS_REGISTRATION_STATUS_PROVISIONAL_DISPLAY,
} from "config/constant";
import { AfterApiIsLogin } from "Utilities/AuthUtility";
import Loading from "components/Loading/Loading";
import { defaultFilterParameters } from "components/Table/TableBase/TableFilter";
import {
  fullNameOrFamilyName,
  getClinetId,
  getUserId,
} from "Utilities/appHelper";
import { isEmpty } from "lodash";
import { DUPLICATION_ERROR } from "Utilities/errorHelper";
import { ApiScheduleUserRegistration } from "Apis/ApiScheduledUserRegistrations";

import CsvDeleteModal from "./Components/CsvDeleteModal/CsvDeleteModal";
import { CircularProgress } from "@material-ui/core";

const filterColumns = ["名前", "職員番号", "権限", "登録日時"];

export const EmployeeList = () => {
  const classes = useStyles();
  const [sortHeader, setSortHeader] = useState(0);
  const [reverseFlg, setReverseFlg] = useState(false);

  const [filterParameters, setFilterParameters] = useState<any>(
    defaultFilterParameters
  );

  const [employeeListArray, setEmployeeListArray] = useState<Array<any>>([]);
  const [employeeListArrayTmp, setEmployeeListArrayTmp] = useState<Array<any>>(
    []
  );
  const [phoneAuthType, setPhoneAuthType] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const tableHeader = phoneAuthType
    ? ["No", "名前", "電話番号", "権限", "登録状態", "登録日時", "削除"]
    : ["No", "名前", "職員番号", "権限", "登録日時", "削除"];

  useEffect(() => {
    ApiUsers(makeCallBack(ProcUsers));
  }, []);

  function makeCallBack(func: any) {
    var list: any = [];
    return (data: any) => {
      func(data).forEach((item: any, key: any) => {
        item.no = key + 1;
        item.authorities_short_display = setAuthoritiesShortDisplay(
          item.authorities
        );
        // MEMO: guestは非表示
        if (item.authorities.includes(USERS_AUTHORITIES_CLIENT_GUEST)) return;

        item.registration_status_display =
          item.registration_status === USERS_REGISTRATION_STATUS_DEFINITIVE
            ? USERS_REGISTRATION_STATUS_DEFINITIVE_DISPLAY
            : item.registration_status === USERS_REGISTRATION_STATUS_PROVISIONAL
            ? USERS_REGISTRATION_STATUS_PROVISIONAL_DISPLAY
            : "-";
        item.create_at_display = mongodbTimeToDisplay(item.create_at);
        list.push(item);
      });
      setEmployeeListArray(list);
    };
  }

  function mongodbTimeToDisplay(strMongoTime: string) {
    if (!(strMongoTime === void 0)) {
      var year = Number(strMongoTime.slice(0, 4));
      var month = Number(strMongoTime.slice(5, 7)) - 1;
      var day = Number(strMongoTime.slice(8, 10));
      var hour = Number(strMongoTime.slice(11, 13));
      var minute = Number(strMongoTime.slice(14, 16));
      var seccond = Number(strMongoTime.slice(17, 19));
      var dt = new Date(year, month, day, hour, minute, seccond);

      dt.setHours(dt.getHours() + ADD_TIME);

      return dt.toLocaleString();
    } else {
      return "-";
    }
  }

  function setAuthoritiesShortDisplay(authorities: any) {
    var output: any[] = [];
    if (authorities.includes(USERS_AUTHORITIES_CLIENT_USER))
      output.push(USERS_AUTHORITIES_SHORT_DISPLAY_CLIENT_USER);
    if (authorities.includes(USERS_AUTHORITIES_CLIENT_ADMIN))
      output.push(USERS_AUTHORITIES_SHORT_DISPLAY_CLIENT_ADMIN);
    if (authorities.includes(USERS_AUTHORITIES_ADMIN_ADMIN))
      output.push(USERS_AUTHORITIES_SHORT_DISPLAY_ADMIN_ADMIN);
    if (authorities.includes(USERS_AUTHORITIES_CLIENT_GUEST))
      output.push(USERS_AUTHORITIES_SHORT_DISPLAY_CLIENT_GUEST);
    return output.join(" / ");
  }

  //-----------add user function------------------------

  const [addUserStatus, setAddUserStatus] = useState(false);
  const [uploadFileStatus, setUploadFileStatus] = useState(false);
  const [completedUserRegistration, setCompletedUserRegistration] =
    useState(false);
  const [reservedUserRegistration, setReservedUserRegistration] =
    useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteUserFinishStatus, setShowDeleteUserFinishStatus] =
    useState(false);

  const [startAddUserStatus, setStartAddUserStatus] = useState(false);
  const [startUploadFileStatus, setStartUploadFileStatus] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [errMsg2, setErrMsg2] = useState("");

  const handleAddUser = async (e: any) => {
    setStartAddUserStatus(true);

    const userId = getUserId();

    var obj = {
      // last_name: e.last_name,
      // first_name: e.first_name,
      phone: e.phone,
      full_name: e.full_name,
      business_email: e.business_email,
      private_email: e.private_email,
      userId: userId,
      //password: e.password,
      //enable: true,
    };

    var response;
    if (phoneAuthType) {
      obj.business_email = undefined;
      response = await ApiPhoneUser(obj);
    } else {
      response = await ApiUser(obj);
    }
    setErrMsg("");
    loginCheck(response);
    if (response["result-status"] === 200) {
      setCompletedUserRegistration(true);
      setAddUserStatus(false);
    } else {
      setErrMsg(
        "ApiUser\nresult-status = " +
          response["result-status"] +
          "\nmessage = " +
          response.message
      );
      setErrorMessageModalStatus(true);
    }
  };

  const handleUploadFile = async (userList: any) => {
    setStartUploadFileStatus(true);

    console.log("userList", userList);

    const payload = {
      userId: getUserId(),
      employeeData: userList,
    };
    try {
      const response = await truncateTable(payload);
      if (response["result-status"] !== 200) {
        throw new Error(response["message"]);
      }
      setCompletedUserRegistration(true);
      setUploadFileStatus(false);
    } catch (e: any) {
      setErrMsg2(e.message);
      setErrorMessage2ModalStatus(true);
    }
    setStartUploadFileStatus(false);
  };

  const handleBulkDelete = async (e: any) => {
    setStartUploadFileStatus(true);

    const params = {
      emailsString: e.userList.join(","),
      userId: getUserId(),
    };
    try {
      await bulkDelete(params);
      setShowDeleteUserFinishStatus(true);
      setUploadFileStatus(false);
    } catch (e) {
      console.log(e);
      setErrMsg2("エラーが発生しました");
      setErrorMessage2ModalStatus(true);
    }
    setStartUploadFileStatus(false);
  };

  const handleUploadPhoneUserFile = async (e: any) => {
    setStartUploadFileStatus(true);

    var tmpErrMsg = "";
    var okNum = 0;
    var ngNum = 0;
    var hasError = false;

    for (var i = 0; i < e.userList.length; i++) {
      var row: any = e.userList[i];
      const fullName = row[0];
      let phoneNumber = row[1];
      if (!phoneNumber.startsWith("0")) {
        phoneNumber = "0" + phoneNumber;
      }
      var obj = {
        full_name: fullName,
        phone: phoneNumber,
      };
      console.log("obj", obj);
      var response: any = await ApiPhoneUser(obj);
      loginCheck(response);
      if (response["result-status"] !== 200) {
        if (!isEmpty(response["result-object"])) {
          const errorCode = response["result-object"]["errorCode"];

          if (errorCode === DUPLICATION_ERROR) {
            tmpErrMsg = "";
          } else {
            tmpErrMsg = "サーバーでエラーが発生しました";
          }
        } else {
          tmpErrMsg = "サーバーでエラーが発生しました";
        }
        hasError = true;
      } else {
        okNum++;
      }
    }

    if (hasError) {
      tmpErrMsg =
        "新規で" + okNum + "名の登録が完了しました。\n" + "\n" + tmpErrMsg;
      setErrMsg2(tmpErrMsg);
      setErrorMessage2ModalStatus(true);
    } else {
      setCompletedUserRegistration(true);
      setUploadFileStatus(false);
    }
    setStartUploadFileStatus(false);
  };
  function loginCheck(data: any) {
    AfterApiIsLogin(data["result-status"]);
  }

  async function scheduleUserRegistraiton(
    targets: { name: String; emailOrPhone: String }[],
    scheduledAt: Date
  ) {
    const params = {
      userId: getUserId(),
      client_id: getClinetId(),
      scheduled_at: scheduledAt,
      targets,
    };
    const response = await ApiScheduleUserRegistration(params);
    return response;
  }

  const [errorMessageModalStatus, setErrorMessageModalStatus] = useState(false);
  const [errorMessage2ModalStatus, setErrorMessage2ModalStatus] =
    useState(false);

  function handleErrorMessageModal() {
    setErrorMessageModalStatus(false);
  }

  function handleErrorMessage2Modal() {
    setErrorMessage2ModalStatus(false);
  }

  function handleAddUserFinish() {
    setCompletedUserRegistration(false);
    window.location.replace(EMPLOYEE_LIST_LINK);
  }

  function handlePreviousRoute() {
    setAddUserStatus(false);
    window.location.replace(EMPLOYEE_LIST_LINK);
  }

  useEffect(() => {
    if (startAddUserStatus === true && completedUserRegistration === false) {
      window.location.replace(EMPLOYEE_LIST_LINK);
    }
  }, [completedUserRegistration]);

  //-----------pagination function start------------------------
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE_NUM);
  const handlePgNum = (pgNum: number) => {
    setCurrentPage(pgNum);
  };
  const handlePgRows = (rows: number) => {
    setPerPage(rows);
    setCurrentPage(0);
  };
  //-----------pagination function end------------------------

  //-------------header sort function----------
  const handleHeader = (e: any) => {
    if (e === tableHeader.findIndex((value) => value === "削除")) {
      return;
    }

    var workReverseFlg = false;
    if (e === sortHeader) {
      workReverseFlg = !reverseFlg;
    }
    setReverseFlg(workReverseFlg);

    setSortHeader(e);

    var list: any = [];
    employeeListArray.forEach((item: any) => {
      list.push(item);
    });

    switch (e) {
      case 0:
        list.sort(compare_no);
        break;
      case 4:
        list.sort(compare_create_at);
        break;
      default:
    }

    if (workReverseFlg) {
      list.reverse();
    }

    setEmployeeListArray(list);
  };

  function compare_no(a: any, b: any) {
    if (a.no < b.no) {
      return -1;
    }
    if (a.no > b.no) {
      return 1;
    }
    return 0;
  }

  function compare_name(a: any, b: any) {
    const a_name = a.last_name + " " + a.first_name;
    const b_name = b.last_name + " " + b.first_name;

    if (a_name < b_name) {
      return -1;
    }
    if (a_name > b_name) {
      return 1;
    }
    return 0;
  }

  function compare_business_email(a: any, b: any) {
    if (a.business_email < b.business_email) {
      return -1;
    }
    if (a.business_email > b.business_email) {
      return 1;
    }
    return 0;
  }

  function compare_private_email(a: any, b: any) {
    const a_private_email =
      a.private_email !== undefined
        ? a.private_email !== null
          ? a.private_email
          : ""
        : "";
    const b_private_email =
      b.private_email !== undefined
        ? b.private_email !== null
          ? b.private_email
          : ""
        : "";

    if (
      a_private_email < b_private_email ||
      (a_private_email === "" && b_private_email !== "")
    ) {
      return -1;
    }
    if (
      a_private_email > b_private_email ||
      (a_private_email !== "" && b_private_email === "")
    ) {
      return 1;
    }
    return 0;
  }

  function compare_phone(a: any, b: any) {
    if (a.phone < b.phone) {
      return -1;
    }
    if (a.phone > b.phone) {
      return 1;
    }
    return 0;
  }

  function compare_authorities_short_display(a: any, b: any) {
    if (a.authorities_short_display < b.authorities_short_display) {
      return -1;
    }
    if (a.authorities_short_display > b.authorities_short_display) {
      return 1;
    }
    return 0;
  }

  function compare_create_at(a: any, b: any) {
    if (a.create_at < b.create_at) {
      return -1;
    }
    if (a.create_at > b.create_at) {
      return 1;
    }
    return 0;
  }

  function compare_enable(a: any, b: any) {
    if (a.enable < b.enable) {
      return -1;
    }
    if (a.enable > b.enable) {
      return 1;
    }
    return 0;
  }

  //-------------filter function----------
  const handleFilter = (
    filterParameters: any,
    filterChangeTrigger: boolean
  ) => {
    if (employeeListArray?.length > 0) {
      var list: any = [];
      var flg = false;

      employeeListArray.forEach((item: any) => {
        flg = false;

        const userName = fullNameOrFamilyName(
          item.full_name,
          item.last_name,
          item.first_name
        );

        if (filterParameters.filterText === "") {
          flg = true;
        } else {
          switch (filterParameters.filterColumn) {
            case 0:
              flg = filterMatchCheck(
                userName,
                filterParameters.filterText,
                filterParameters.filterMode
              );
              break;
            case 1:
              flg = filterMatchCheck(
                item.employee_id,
                filterParameters.filterText,
                filterParameters.filterMode
              );
              break;
            case 2:
              flg = filterMatchCheck(
                item.authorities_short_display,
                filterParameters.filterText,
                filterParameters.filterMode
              );
              break;
            case 3:
              flg = filterMatchCheck(
                item.create_at_display,
                filterParameters.filterText,
                filterParameters.filterMode
              );
              break;
            default:
          }
        }

        if (flg) {
          list.push(item);
        }
      });
      setEmployeeListArrayTmp(list);
      setLoading(false);
    }

    if (filterChangeTrigger) {
      setFilterParameters({
        ...filterParameters,
        filterColumn: filterParameters.filterColumn,
        filterMode: filterParameters.filterMode,
        filterText: filterParameters.filterText,
      });
    }
  };

  function filterMatchCheck(
    targetStr: string,
    searchStr: string,
    checkType: number
  ) {
    targetStr = targetStr?.toLowerCase();
    searchStr = searchStr?.toLowerCase();

    var result = false;

    switch (checkType) {
      case FILTER_MODE_CONTAINS:
        result = targetStr?.indexOf(searchStr) >= 0;
        break;
      case FILTER_MODE_STARTS_WITH:
        result = targetStr?.startsWith(searchStr);
        break;
      case FILTER_MODE_ENDS_WITH:
        result = targetStr?.endsWith(searchStr);
        break;
      default:
    }

    return result;
  }

  useEffect(() => {
    handleFilter(filterParameters, false);
  }, [employeeListArray]);

  //--------------service detail functin----------
  /* const handleServiceDetail = (e: any) => {
    navigate(`${EMPLOYEE_DETAIL_LINK}/${e}`);
  }; */

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.clientListTitle}>利用者一覧</div>
        <div className={classes.clientListSmallTitle}>
          以下より、利用者の追加・編集・削除ができます。
        </div>
        <div className={classes.tableTool}>
          <div className={classes.addBtnParent}>{`利用者追加`}</div>
          <div
            className={classes.addBtn}
            onClick={() => setUploadFileStatus(true)}
          >
            {`CSV更新`}
            <i className="fas fa-plus"></i>
          </div>
        </div>
        {loading ? (
          <div className={classes.loading}>
            <CircularProgress size="1rem" color="inherit" />
          </div>
        ) : (
          <ActionTable
            className={classes.tableRoot}
            handlePgNum={handlePgNum}
            handlePgRows={handlePgRows}
            totalCnt={employeeListArrayTmp?.length}
            PgNum={currentPage}
            PgRows={perPage}
            maxPgRows={employeeListArrayTmp?.length}
            filterColumns={filterColumns}
            changeFilterParameters={(e) => handleFilter(e, true)}
            filterParameters={filterParameters}
            tableContent={
              <TableContent
                rows={employeeListArrayTmp}
                phoneAuthType={phoneAuthType}
                //onRow={(e) => handleServiceDetail(e)}
                pageNumber={currentPage}
                perPageNumber={perPage}
                columns={tableHeader}
                sortHeader={sortHeader}
                reverseFlg={reverseFlg}
                headerSort={(e) => handleHeader(e)}
              />
            }
          />
        )}

        <AddUserModal
          show={addUserStatus}
          onClose={handlePreviousRoute}
          action={(e) => handleAddUser(e)}
          phoneAuthType={phoneAuthType}
        />
        <UploadFileModal
          show={uploadFileStatus}
          phoneAuthType={phoneAuthType}
          onClose={handlePreviousRoute}
          action={(e) => handleUploadFile(e)}
        />
        <CsvDeleteModal
          show={showDeleteModal}
          phoneAuthType={phoneAuthType}
          onClose={handlePreviousRoute}
          action={(e) => handleBulkDelete(e)}
        />
        <Loading show={startUploadFileStatus} />
        <MessageModal
          title="従業員登録完了"
          description={
            "従業員の登録が完了しました。アプリのダウンロードをお伝えください。"
          }
          show={completedUserRegistration}
          onClose={() => setCompletedUserRegistration(false)}
          action={handleAddUserFinish}
        />
        <MessageModal
          title="従業員削除完了"
          description="削除が完了しました"
          show={showDeleteUserFinishStatus}
          onClose={() => setShowDeleteUserFinishStatus(false)}
          action={handleAddUserFinish}
        />
        <MessageModal
          title="従業員登録予約完了"
          description={"従業員の登録予約が完了しました。"}
          show={reservedUserRegistration}
          onClose={() => setReservedUserRegistration(false)}
          action={handleAddUserFinish}
        />

        <MessageModal
          title="従業員追加エラー"
          description={`従業員追加エラーが発生しました。\n\n<<エラーメッセージ>>\n${errMsg}`}
          show={errorMessageModalStatus}
          onClose={() => setErrorMessageModalStatus(false)}
          action={handleErrorMessageModal}
        />
        <MessageModal
          title="処理結果"
          description={errMsg2}
          show={errorMessage2ModalStatus}
          onClose={() => setErrorMessage2ModalStatus(false)}
          action={handleErrorMessage2Modal}
        />
      </div>
    </div>
  );
};
