import { useStyles } from "./ApplicationTableContentStyles";
import { TableRow, TableCell } from "@material-ui/core";
import clsx from "clsx";
import TableRoot from "components/Table/TableBase/TableRoot";
import { useState } from "react";

interface StagingTableProps {
  columns?: any;
  rows: any;
  pageNumber: number;
  perPageNumber: number;
  sortHeader: number;
  reverseFlg: boolean;
  headerSort: (e: any) => void;
  onRow: (e: any) => void;
}

const ApplicationTableContent = ({
  pageNumber,
  perPageNumber,
  columns,
  rows,
  sortHeader,
  reverseFlg,
  headerSort,
  onRow,
}: StagingTableProps) => {
  const classes = useStyles();

  const [errMsg, setErrMsg] = useState("");

  const tableRows =
    rows !== undefined ? (
      rows
        ?.slice(pageNumber * perPageNumber, (pageNumber + 1) * perPageNumber)
        .map((row: any, key: any) => (
          <TableRow key={key}>
            <TableCell
              className={clsx(classes.tableCell)}
              onClick={() => onRow(row)}
            >
              {row.no}
            </TableCell>
            <TableCell
              className={clsx(classes.tableCell, classes.greyout)}
              onClick={() => onRow(row)}
            >
              非表示中
            </TableCell>
            <TableCell
              className={clsx(classes.tableCell)}
              onClick={() => onRow(row)}
            >
              {row.item_type.indexOf("ServiceApplications") >= 0
                ? row.application_datetime_display
                : ""}
            </TableCell>
            {/* <TableCell
              className={clsx(
                classes.tableCell,
                !row.user_enable && classes.greyout
              )}
              onClick={() => onRow(row)}
            >
              {row.user_name_display}
            </TableCell> */}
            <TableCell
              className={clsx(
                classes.tableCell,
                !row.user_enable && classes.greyout
              )}
              onClick={() => onRow(row)}
            >
              {row.devise}
              {/* ここはDBを更新する必要あり{row.business_email_display} */}
            </TableCell>
            <TableCell
              className={clsx(classes.tableCell)}
              onClick={() => onRow(row)}
            >
              {row.service_title_display}
            </TableCell>
          </TableRow>
        ))
    ) : (
      <></>
    );
  return (
    <>
      {rows?.length === 0 || rows === null ? (
        <div className={classes.emptyTable}>データなし</div>
      ) : (
        <TableRoot
          columns={columns}
          rows={tableRows}
          sortHeader={sortHeader}
          reverseFlg={reverseFlg}
          headerClick={(e) => headerSort(e)}
        />
      )}
    </>
  );
};

export default ApplicationTableContent;
