import { AfterApiIsLogin } from "Utilities/AuthUtility";
import { REQUEST_API_URL } from "../config/constant";
import { getClinetId, getToken, getUserId } from "Utilities/appHelper";

export function ApiUsers(callback: any) {
  const userId = getUserId();
  const token = getToken();

  const params = { userId: userId };
  const query = new URLSearchParams(params);
  fetch(REQUEST_API_URL + `/users?${query}`, {
    method: "GET",
    mode: "cors",
    cache: "no-store",
    credentials: "include",
    headers: {
      Authorization: "Bearer " + token,
    },
    redirect: "error",
    referrerPolicy: "no-referrer-when-downgrade",
  })
    .then((res) => res.json())
    .then((data) => {
      AfterApiIsLogin(data["result-status"]); // ここが問題
      callback(data["result-object"]);
    });
}

export async function ApiDiffUsers(data: any) {
  const token = getToken();

  const response: any = await fetch(REQUEST_API_URL + `/users/diff`, {
    method: "POST",
    mode: "cors",
    cache: "no-store",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    redirect: "error",
    referrerPolicy: "no-referrer-when-downgrade",
    body: JSON.stringify(data),
  });

  return response.json();
}

export async function truncateTable(data: any) {
  const token = getToken();
  const response: any = await fetch(
    REQUEST_API_URL + "/users/truncateAndUpdate",
    {
      method: "POST",
      mode: "cors",
      cache: "no-store",
      credentials: "include",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      redirect: "error",
      referrerPolicy: "no-referrer-when-downgrade",
      body: JSON.stringify(data),
    }
  );

  return response.json();
}

export async function bulkDelete(data: any) {
  const token = getToken();
  const params = { userId: data.userId, emailsString: data.emailsString };
  const query = new URLSearchParams(params);
  const response: any = await fetch(REQUEST_API_URL + `/users/chunk?${query}`, {
    method: "DELETE",
    mode: "cors",
    cache: "no-store",
    credentials: "include",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    redirect: "error",
    referrerPolicy: "no-referrer-when-downgrade",
  });

  return response.json();
}
