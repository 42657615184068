import { Theme, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  displayNone: {
    display: "none",
  },
  modalRoot: {
    padding: "30px 35px",
    boxSizing: "border-box",
    width: "800px",
    height: "fit-content",
    maxHeight: "90vh",
    overflowY: "scroll",
    background: "#fff",
    boxShadow: "0px 0px 30px rgba(55, 55, 79, 0.05)",
    borderRadius: "10px",
    textAlign: "left",
    margin: "auto",
    [theme.breakpoints.down(769)]: {
      minWidth: "100% !important",
      width: "100% !important",
    },
  },
  form: {
    marginBottom: "10px",
    textAlign: "left",
  },
  formTitle: {
    color: "#000",
    fontFamily: "Noto Sans JP", //20230111
    fontSize: "15px",
    fontWeight: 700,
    lineHeight: 1.4,
    letterSpacing: "0em",
    textAlign: "left",
    transitionDelay: "400ms",
    transitionDuration: "1000ms",
    marginBottom: "10px",
    [theme.breakpoints.down(440)]: {
      fontSize: "12px",
      transitionDelay: "300ms",
      transitionDuration: "1000ms",
    },
  },
  scheduleContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    marginTop: "4px",
    paddingLeft: "16px",
  },
  scheduleInputDate: {
    height: "20px",
    padding: "8px 12px",
    borderRadius: "8px",
    border: "1px solid rgb(191, 194, 199)",
    cursor: "pointer",
    "&:disabled": {
      backgroundColor: "#F7F7F7",
      color: "#666",
      cursor: "not-allowed",
    },
  },
  scheduleInputTime: {
    height: "20px",
    padding: "8px 12px",
    borderRadius: "8px",
    border: "1px solid rgb(191, 194, 199)",
    cursor: "pointer",
    "&:disabled": {
      backgroundColor: "#F7F7F7",
      color: "#666",
      cursor: "not-allowed",
    },
  },

  deleteContainer: {
    marginTop: "32px",
    backgroundColor: "#f7f7f7",
    border: "2px solid #ffcccc",
    borderRadius: "8px",
    padding: "24px",
  },

  deleteContainerTitle: {
    textAlign: "left",
    fontWeight: 700,
    fontSize: "18px",
  },

  scheduleTitle: {
    fontWeight: 700,
    fontSize: "14px",
    margin: "16px 0 8px",
  },
  scheduleTable: {
    borderSpacing: "0",
    borderTop: "1px solid #EEE",
  },
  scheduleHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  scheduleRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "14px",
    borderBottom: "1px solid #EEE",
    padding: "8px 0",
    animation: "$fadeIn 1s ease-in-out",
  },
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
      transform: "translateY(-10px)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0)",
    },
  },
  radioLabel: {
    fontSize: "15px",
    cursor: "pointer",
  },
  fileInput: {
    backgroundColor: "#ffffff",
    color: "rgb(31, 41, 55)",
    cursor: "pointer",
    border: "1px solid rgb(191, 194, 199)",
    borderRadius: "8px",
    width: "320px",
    marginLeft: "16px",
    "&::file-selector-button, &::-webkit-file-upload-button": {
      backgroundColor: "rgb(209, 213, 219)",
      fontWeight: 700,
      color: "#000",
      border: "none",
      cursor: "pointer",
      borderRight: "1px solid rgb(191, 194, 199)",
      padding: "11px 12px",
      marginRight: "16px",
      transition: "all 0.2s ease-out",
      "&:hover": {
        opacity: 0.8,
      },
    },
  },
  symbol: {
    color: "#f23a3c",
    fontFamily: "Noto Sans JP", //20230111
    fontSize: "15px",
    fontWeight: 700,
    lineHeight: 1.4,
    marginRight: "5px",
  },
  formInput: {
    color: "#000",
    fontFamily: "Noto Sans JP", //20230111
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "29px",
  },
  loaderWrapper: {
    position: "fixed",
    zIndex: 99998,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    overflowY: "scroll",
    padding: "16px",
    display: "flex",
    justifyContent: "center",
    // alignItems: "center",
  },
  title: {
    fontStyle: "normal",
    textAlign: "center",
    //fontFamily: "Montserrat",
    fontFamily: "Noto Sans JP",
    fontWeight: 600,
    fontSize: "20px",
    marginBottom: "24px",
    color: "#000",
  },
  container: {
    width: " 90%",
    margin: "0 auto",
  },
  formContainer: {
    backgroundColor: "#f7f7f7",
    padding: "24px",
    borderRadius: "8px",
  },
  mainContainerFlex: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "16px",
  },
  mainContainer: {
    // padding: "0px 50px",
    [theme.breakpoints.down(540)]: {
      padding: 0,
    },
  },
  descContainer: {
    color: "#000",
    fontFamily: "Noto Sans JP", //20230111
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "29px",
    marginTop: "5px",
    //marginBottom: "30px",
    marginBottom: "10px",
  },
  descDetail: {
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "left",
    marginBottom: "20px",
  },
  descDetailTitle: {
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "10px",
  },
  anotation: {
    fontSize: "12px",
    color: "#f23a3c",
    fontWeight: 900,
  },
  beforeDownload: {
    marginBottom: "0px",
  },
  /* downloadBig: {
    textAlign: "left",
    lineHeight: "18px",
    "& a": {
      color: "#0066ff",
      textDecoration: "none",
    },
    "&:hover": {
      textDecoration: "underline",
      opacity: 0.8,
    },
  }, */
  /* downloadSmall: {
    fontSize: "14px",
    display: "flex",
    marginBottom: "10px",
  }, */
  parentheses: {
    display: "inline",
    color: "#666666",
  },
  smallLink: {
    display: "inline-block",
    backgroundColor: "#E6F4E6",
    padding: "14px 40px",
    borderRadius: "8px",
    color: "#000",
    textDecoration: "none",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "1",
    "&:hover": {
      opacity: 0.8,
    },
  },
  btnContainer: {
    marginTop: "50px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down(570)]: {
      display: "block",
    },
  },
  cancelBtn: {
    width: "100% !important",
    marginRight: "15px",
  },
  confirmBtn: {
    width: "100% !important",
    [theme.breakpoints.down(570)]: {
      marginLeft: "0px",
    },
  },
  note: {
    display: "flex",
    gap: "8px",
    fontSize: "12px",
    lineHeight: "1.5",
    marginBottom: "24px",
  },
  name: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  formMiddle: {
    maxWidth: "100%",
    width: "315px",
  },
  formShort: {
    maxWidth: "100%",
    width: "189px",
  },
  marginRight24: {
    marginRight: "24px",
  },
  errorMessage: {
    color: "red",
    fontFamily: "Noto Sans JP", //20230111
    fontWeight: 800,
    fontSize: "14px",
    lineHeight: "22px",
    textAlign: "left",
  },
}));
