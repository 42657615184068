import sidebar1 from "../assets/icons/sidebar-icon1.png";
import sidebar2 from "../assets/icons/sidebar-icon2.png";
import sidebar3 from "../assets/icons/sidebar-icon3.png";
import sidebar4 from "../assets/icons/sidebar-icon4.png";
import sidebar5 from "../assets/icons/sidebar-icon5.png";

export const REQUEST_API_URL = process.env.REACT_APP_REQUEST_API_URL;
export const REQUEST_IMG_URL = process.env.REACT_APP_REQUEST_IMG_URL;
export const REQUEST_AVATAR_IMG_URL =
  process.env.REACT_APP_REQUEST_AVATAR_IMG_URL;
export const SECURE_SITE_URL = process.env.REACT_APP_SECURE_SITE_URL;
export const SERVICES_DIR_NAME = "services";

export const RES_STATUS_NOT_LOGIN = 401;
export const RES_STATUS_OK = 200;

export const ADD_TIME = 9;

export const USERS_AUTHORITIES_ADMIN_ADMIN = "admin_admin";
export const USERS_AUTHORITIES_CLIENT_ADMIN = "client_admin";
export const USERS_AUTHORITIES_CLIENT_USER = "client_user";
export const USERS_AUTHORITIES_CLIENT_GUEST = "client_guest";

export const LOGIN_SITE_SECURE = "secure.shisaku-pad.co.jp";
export const LOGIN_SITE_CLIENT = "client.shisaku-pad.co.jp";
export const LOGIN_HISTORYS_LOGIN_TYPE_NOMAL = "nomal";
export const LOGIN_HISTORYS_LOGIN_TYPE_REMEMBER_ME = "remember_me";

export const ACCOUNT_LINK = "/account";
export const ACCOUNT_SETTING_LINK = "/account/setting";
export const EMPLOYEE_LIST_LINK = "/employee_list";
export const EMPLOYEE_DETAIL_LINK = "/employee_list/users";
export const EMPLOYEE_EDIT_LINK = "/employee_list/users/edit";
export const ACCESS_REPORT_LINK = "/report/acccess";
export const APPLICATION_REPORT_LINK = "/report/application";
export const FIRST_LOGIN_REPORT_LINK = "/report/first_login";

export const USAGE_LINK = "/usage";
export const CONTACT_LINK = "/usage/contact";
export const CONTACT_COMPLETE_PAGE_LINK = "/contact/thanks";

export const USAGES_TYPE_ID_CLIENT_USER = 1;
export const USAGES_TYPE_ID_CLIENT_ADMIN = 2;

export const PRIVACY_POLICY_URL = "https://leafea.co.jp/privacy-policy";
export const COMPANY_ABOUT_URL = "https://leafea.co.jp/company";
export const COMPANY_TERM_URL = "https://leafea.co.jp/terms";
export const SERVICE_NAME = "MOEベネフィット";
export const COPYRIGHT = "©︎ Leafea Inc. All Right Reserved.";

export const QUESTION_TYPE_ID_FROM_SECURE_SITE = 1;
export const QUESTION_TYPE_ID_FROM_CLIENT_SITE = 2;

export const FILTER_MODE_CONTAINS = 0;
export const FILTER_MODE_STARTS_WITH = 1;
export const FILTER_MODE_ENDS_WITH = 2;

export const DEFAULT_PER_PAGE_NUM = 20;

export const USERS_AUTHORITIES_DISPLAY_ADMIN_ADMIN = "運営者";
export const USERS_AUTHORITIES_DISPLAY_CLIENT_ADMIN = "利用企業管理者";
export const USERS_AUTHORITIES_DISPLAY_CLIENT_USER = "利用者";
export const USERS_AUTHORITIES_SHORT_DISPLAY_ADMIN_ADMIN = "運営";
export const USERS_AUTHORITIES_SHORT_DISPLAY_CLIENT_ADMIN = "管理";
export const USERS_AUTHORITIES_SHORT_DISPLAY_CLIENT_USER = "利用";
export const USERS_AUTHORITIES_SHORT_DISPLAY_CLIENT_GUEST = "招待";

export const USERS_REGISTRATION_STATUS_PROVISIONAL = "provisional";
export const USERS_REGISTRATION_STATUS_DEFINITIVE = "definitive";
export const USERS_REGISTRATION_STATUS_PROVISIONAL_DISPLAY = "仮登録";
export const USERS_REGISTRATION_STATUS_DEFINITIVE_DISPLAY = "本登録";

export const APPLICATION_MAIL_TYPE_BUSINESS = 1;
export const APPLICATION_MAIL_TYPE_PRIVATE = 2;

export const SidbarData: SidebarItem[] = [
  // {
  //   img: sidebar1,
  //   content: "アカウント設定",
  //   link: SECURE_SITE_URL + ACCOUNT_LINK,
  // },
  {
    img: sidebar2,
    content: "利用者一覧",
    link: EMPLOYEE_LIST_LINK,
  },
  {
    img: sidebar5,
    content: "利用状況",
    link: ACCESS_REPORT_LINK,
    children: [
      {
        text: "初回ログイン完了状況",
        url: FIRST_LOGIN_REPORT_LINK,
      },
      {
        text: "ログイン履歴",
        url: ACCESS_REPORT_LINK,
      },
      {
        text: "割引利用履歴",
        url: APPLICATION_REPORT_LINK,
      },
    ],
  },
  // {
  //   img: sidebar3,
  //   content: "利用方法",
  //   link: USAGE_LINK,
  // },
  // {
  //   img: sidebar4,
  //   content: "お問い合わせ",
  //   link: CONTACT_LINK,
  // },
];

// MEMO:　型の情報は分離したい
export interface SidebarItem {
  img: string;
  content: string;
  link: string;
  children?: DropSideMenuType[];
}

export interface DropSideMenuType {
  text: string;
  url: string;
}
