import { Theme, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  displayNone: {
    display: "none",
  },
  loaderWrapper: {
    position: "fixed",
    zIndex: 99998,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    overflowY: "scroll",
    padding: "16px",
    display:"flex",
    justifyContent: "center",
    alignItems:"center",
  },
  modalRoot: {
    padding: "30px 35px",
    boxSizing: "border-box",
    width: "620px",
    height: "fit-content",
    background: "#fff",
    boxShadow: "0px 0px 30px rgba(55, 55, 79, 0.05)",
    borderRadius: "10px",
    textAlign: "center",
    margin: "auto",
    [theme.breakpoints.down(769)]: {
      minWidth: "100% !important",
      width: "100% !important",
    },
  },
  title: {
    margin: "10px",
  },
  mainContainer: {
    
  },
  /* "#load": {
    position: "absolute",
    width: "600px",
    height: "36px",
    left: "50%",
    top: "40%",
    marginLeft: "-300px",
    overflow: "visible",
    WebkitUserSelect: "none",
    MozUserSelect: "none",
    MsUserSelect: "none",
    userSelect: "none",
    cursor: "default",
  },
  "#load div": {
    position: "absolute",
    width: "20px",
    height: "36px",
    opacity: "0",
    fontFamily: "Helvetica, Arial, sans-serif",
    animation: "move 2s linear infinite",
    OAnimation: "move 2s linear infinite",
    MozAnimation: "move 2s linear infinite",
    WebkitAnimation: "move 2s linear infinite",
    transform: "rotate(180deg)",
    OTransform: "rotate(180deg)",
    MozTransform: "rotate(180deg)",
    WebkitTransform: "rotate(180deg)",
    color: "#35C4F0",
  },
  "#load div:nth-child(2)": {
    animationDelay: "0.2s",
    OAnimationDelay: "0.2s",
    MozAnimationDelay: "0.2s",
    WebkitAnimationDelay: "0.2s",
  },
  "#load div:nth-child(3)": {
    animationDelay: "0.4s",
    OAnimationDelay: "0.4s",
    MozAnimationDelay: "0.4s",
    WebkitAnimationDelay: "0.4s",
  },
  "#load div:nth-child(4)": {
    animationDelay: "0.6s",
    OAnimationDelay: "0.6s",
    MozAnimationDelay: "0.6s",
    WebkitAnimationDelay: "0.6s",
  },
  "#load div:nth-child(5)": {
    animationDelay: "0.8s",
    OAnimationDelay: "0.8s",
    MozAnimationDelay: "0.8s",
    WebkitAnimationDelay: "0.8s",
  },
  "#load div:nth-child(6)": {
    animationDelay: "1s",
    OAnimationDelay: "1s",
    MozAnimationDelay: "1s",
    WebkitAnimationDelay: "1s",
  },
  "#load div:nth-child(7)": {
    animationDelay: "1.2s",
    OAnimationDelay: "1.2s",
    MozAnimationDelay: "1.2s",
    WebkitAnimationDelay: "1.2s",
  },
  "@keyframes move": {
    "& 0%": {
      left: "0",
      opacity: "0",
    },
    "& 35%": {
      left: " 41%", 
      MozTransform: "rotate(0deg)",
      WebkitTransform: "rotate(0deg)",
      OTransform: "rotate(0deg)",
      transform: "rotate(0deg)",
      opacity: "1",
    },
    "& 65%": {
      left: "59%", 
      MozTransform: "rotate(0deg)", 
      WebkitTransform: "rotate(0deg)", 
      OTransform: "rotate(0deg)",
      transform: "rotate(0deg)", 
      opacity: "1",
    },
    "& 100%": {
      left: "100%", 
      MozTransform: "rotate(-180deg)", 
      WebkitTransform: "rotate(-180deg)", 
      OTransform: "rotate(-180deg)", 
      transform: "rotate(-180deg)",
      opacity: "0",
    },
  },
  "@-moz-keyframes move": {
    "& 0%": {
      left: "0", 
      opacity: "0",
    },
    "& 35%": {
      left: "41%", 
      MozTransform: "rotate(0deg)", 
      transform: "rotate(0deg)",
      opacity: "1",
    },
    "& 65%": {
      left: "59%", 
      MozTransform: "rotate(0deg)", 
      transform: "rotate(0deg)",
      opacity: "1",
    },
    "& 100%": {
      left: "100%", 
      MozTransform: "rotate(-180deg)", 
      transform: "rotate(-180deg)",
      opacity: "0",
    },
  },
  "@-webkit-keyframes move": {
    "& 0%": {
      left: "0", 
      opacity: "0",
    },
    "& 35%": {
      left: "41%", 
      WebkitTransform: "rotate(0deg)", 
      transform: "rotate(0deg)", 
      opacity: "1",
    },
    "& 65%": {
      left: "59%", 
      WebkitTransform: "rotate(0deg)", 
      transform: "rotate(0deg)", 
      opacity: "1",
    },
    "& 100%": {
      left: "100%",
      WebkitTransform: "rotate(-180deg)", 
      transform: "rotate(-180deg)", 
      opacity: "0",
    },
  },
  "@-o-keyframes move": {
    "& 0%": {
      left: "0", 
      opacity: "0",
    },
    "& 35%": {
      left: "41%", 
      OTransform: "rotate(0deg)", 
      transform: "rotate(0deg)", 
      opacity: "1",
    },
    "& 65%": {
      left: "59%", 
      OTransform: "rotate(0deg)", 
      transform: "rotate(0deg)", 
      opacity: "1",
    },
    "& 100%": {
      left: "100%", 
      OTransform: "rotate(-180deg)", 
      transform: "rotate(-180deg)", 
      opacity: "0",
    },
  }, */
  /* loader: {
    position: "absolute",
    top: "calc(50% - 32px)",
    left: "calc(50% - 32px)",
    width: "64px",
    height: "64px",
    borderRadius: "50%",
    perspective: "800px",
  },
  inner: {
    position: "absolute",
    boxSizing: "border-box",
    width: "100%",
    height: "100%",
    borderRadius: "50%",  
  },
  one: {
    left: "0%",
    top: "0%",
    animation: "rotate-one 1s linear infinite",
    borderBottom: "3px solid #EFEFFA",
  },
  two: {
    right: "0%",
    top: "0%",
    animation: "rotate-two 1s linear infinite",
    borderRight: "3px solid #EFEFFA",
  },
  three: {
    right: "0%",
    bottom: "0%",
    animation: "rotate-three 1s linear infinite",
    borderTop: "3px solid #EFEFFA",
  },
  "@keyframes rotate-one": {
    "0%": {
      transform: "rotateX(35deg) rotateY(-45deg) rotateZ(0deg)",
    },
    "100%": {
      transform: "rotateX(35deg) rotateY(-45deg) rotateZ(360deg)",
    },
  },
  "@keyframes rotate-two": {
    "0%": {
      transform: "rotateX(50deg) rotateY(10deg) rotateZ(0deg)",
    },
    "100%": {
      transform: "rotateX(50deg) rotateY(10deg) rotateZ(360deg)",
    },
  },
  "@keyframes rotate-three": {
    "0%": {
      transform: "rotateX(35deg) rotateY(55deg) rotateZ(0deg)",
    },
    "100%": {
      transform: "rotateX(35deg) rotateY(55deg) rotateZ(360deg)",
    },
  }, */
}));
