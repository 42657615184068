import ActionButton from "components/ActionButton/ActionButton";
import ActionInput from "components/ActionInput/ActionInput";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useStyles } from "./LoginStyle";
import { authClient } from "Auth/authClient";
import { createLoginMonitor } from "../../Utilities/LoginManager";
import ActionCheckbox from "components/ActionCheckbox/ActionCheckbox";
import { RES_STATUS_OK } from "config/constant";
import { isEmpty } from "lodash";

export const Login = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [employeeCode, setemployeeCode] = useState("");
  const [confirmEmployeeCode, setConfirmEmployeeCode] = useState("");
  const loginMonitor = createLoginMonitor();

  const [errMsg, setErrMsg] = useState("");

  const handleNext = async () => {
    // validation
    if (isEmpty(employeeCode)) {
      setErrMsg("職員番号を入力してください");
      return;
    }

    if (isEmpty(confirmEmployeeCode)) {
      setErrMsg("職員番号（確認）を入力してください");
      return;
    }

    if (employeeCode !== confirmEmployeeCode) {
      setErrMsg("職員番号と確認番号が一致しません");
      return;
    }

    var dirs = window.location.pathname.split("/");
    const email = `${employeeCode}@moe.com`;
    const response = await authClient(email, employeeCode);
    if (response["result-status"] === RES_STATUS_OK) {
      const user_key = response["result-object"]["user"]["id"];
      const token = response["result-object"]["token"];
      const client_id = response["result-object"]["clientId"];
      localStorage.setItem("user_key", user_key);
      localStorage.setItem("token", token);
      localStorage.setItem("client_id", client_id);
      loginMonitor.login();
    } else {
      setErrMsg(response["message"]);
      return;
    }
    if (dirs[1] === "login") {
      window.location.replace("/");
    } else {
      window.location.reload();
    }
  };

  return (
    <>
      <div className={classes.loaderWrapper}>
        <div className={classes.modalRoot}>
          <div className={classes.title}>ログイン</div>
          <div className={classes.mainContainer}>
            <div className={classes.errorMessage}>{errMsg}</div>
            <div className={classes.form}>
              <div className={classes.formTitle}>職員番号</div>
              <ActionInput
                value={employeeCode}
                action={(e) => setemployeeCode(e)}
                placeholder="MOEBENE01"
                className={classes.formInput}
              />
            </div>
            <div className={classes.form}>
              <div className={classes.formTitle}>職員番号(確認)</div>
              <ActionInput
                value={confirmEmployeeCode}
                action={(e) => setConfirmEmployeeCode(e)}
                placeholder="MOEBENE01"
                className={classes.formInput}
              />
            </div>
          </div>
          <div className={classes.btnContainer}>
            <ActionButton
              content="キャンセル"
              className={classes.cancelBtn}
              action={() => {
                window.location.replace("/");
              }}
            />
            <ActionButton
              content="ログイン"
              type="dark"
              className={classes.confirmBtn}
              action={handleNext}
            />
          </div>
        </div>
      </div>
    </>
  );
};
