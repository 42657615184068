import { useStyles } from "./UsersTableContentStyles";
import { TableRow, TableCell } from "@material-ui/core";
import clsx from "clsx";
import TableRoot from "components/Table/TableBase/TableRoot";
import MailIcon from "@material-ui/icons/Mail";

interface StagingTableProps {
  columns?: any;
  rows: any;
  pageNumber: number;
  perPageNumber: number;
  sortHeader: number;
  reverseFlg: boolean;
  authType: string | null;
  headerSort: (e: any) => void;
  onRow: (e: any) => void;
  handleConfirmModal: (e: any) => void;
}

const UnloggedUsersTableContent = ({
  pageNumber,
  perPageNumber,
  columns,
  rows,
  sortHeader,
  reverseFlg,
  authType,
  headerSort,
  onRow,
  handleConfirmModal,
}: StagingTableProps) => {
  const classes = useStyles();

  const tableRows =
    rows !== undefined ? (
      rows
        ?.slice(pageNumber * perPageNumber, (pageNumber + 1) * perPageNumber)
        .map((row: any, key: any) => (
          <TableRow key={key}>
            <TableCell
              className={clsx(classes.tableCell)}
              onClick={() => onRow(row)}
            >
              {row.no}
            </TableCell>
            <TableCell
              className={clsx(classes.tableCell)}
              onClick={() => onRow(row)}
            >
              {row.full_name}
            </TableCell>
            <TableCell
              className={clsx(classes.tableCell)}
              onClick={() => onRow(row)}
            >
              {row.employee_id}
            </TableCell>
          </TableRow>
        ))
    ) : (
      <></>
    );
  return (
    <>
      {rows?.length === 0 || rows === null ? (
        <div className={classes.emptyTable}>データなし</div>
      ) : (
        <TableRoot
          columns={columns}
          rows={tableRows}
          sortHeader={sortHeader}
          reverseFlg={reverseFlg}
          headerClick={(e) => headerSort(e)}
        />
      )}
    </>
  );
};

export default UnloggedUsersTableContent;
