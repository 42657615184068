import React, { useContext, useEffect, useState } from "react";
import { REQUEST_API_URL, RES_STATUS_OK } from "../config/constant";
import { getUser } from "Auth/authClient";
import { isEmpty } from "lodash";
import DotLoading from "components/Loading/DotLoading";
// import DotLoading from "components/Loading/DotLoading";

type loginManagerProp = {
  isLogin: boolean;
  setIsLogin: React.Dispatch<React.SetStateAction<boolean>>;
};

export type loginMonitor = {
  logout: () => void;
  login: () => void;
  isLogin: () => boolean;
  /* checkLogin:(responseStatus:number)=>void */
};

function createContext<T>() {
  const context = React.createContext<T | undefined>(undefined);
  const useCtx = () => {
    const c = useContext(context);
    if (!c) throw new Error("useCtx must be inside a Provider with a value");
    return c;
  };
  return [useCtx, context.Provider] as const;
}

const [loginSts, SetLoginProvider] = createContext<loginManagerProp>();

export const createLoginMonitor = (): loginMonitor => {
  const context = loginSts();
  const login = () => {
    context.setIsLogin(true);
  };

  const logout = () => {
    localStorage.removeItem("user_key");
    localStorage.removeItem("token");
    context.setIsLogin(false);
  };

  const isLogin = () => {
    return context.isLogin;
  };

  /*const checkLogin = (responseStatus:number) =>{
        context.setIsLogin(responseStatus !== RES_STATUS_NOT_LOGIN);
    }*/

  return {
    logout: logout,
    isLogin: isLogin,
    login: login,
    /* checkLogin:checkLogin */
  };
};

export const LoginProxy = ({ children }: { children?: React.ReactNode }) => {
  const [isLogin, setIsLogin] = useState<boolean>(false); //初めからログインしていることにする？
  const [loading, setLoading] = useState(true);

  const loginObj: loginManagerProp = {
    isLogin: isLogin,
    setIsLogin: setIsLogin,
  };

  useEffect(() => {
    async function fetchUser() {
      const token = localStorage.getItem("token") || "";
      const userId = localStorage.getItem("user_key") || "";

      await getUser(userId, token)
        .then((data) => {
          if (data["result-status"] === RES_STATUS_OK) {
            const user_key = data["result-object"]["user_key"];
            const token = data["result-object"]["token"];
            localStorage.setItem("user_key", userId);
            localStorage.setItem("token", token);
            loginObj.setIsLogin(data["result-status"] === RES_STATUS_OK);
          }
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          loginObj.setIsLogin(false);
        });
    }
    fetchUser();
  }, []);

  if (loading) {
    return <div><DotLoading /></div>;
  }

  return <SetLoginProvider value={loginObj}>{children}</SetLoginProvider>;
};
