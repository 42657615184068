import { Theme, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  tableCell: {
    fontSize: "16px !important",
    fontFamily: "Noto Sans JP",
    fontWeight: "normal",
    "&.MuiTableCell-root": {
      padding: "19.565px 10px 19.565px 20px !important",
    },
  },
  sendMailButton: {
    maxWidth: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold",
    fontSize: "15px",
    gap: "4px",
    padding: "8px 4px 8px 0",
    borderRadius: "4px",
    transition: "background-color 0.2s ease-out",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ffffff",
    },
  },
  emptyTable: {
    fontFamily: "Noto Sans JP",
    fontSize: "40px",
    color: "white",
    textAlign: "center",
    margin: "100px auto",
    height: "326px",
  },
  action: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  actionIcon: {
    "& i": {
      cursor: "pointer",
    },
    "&:hover": {
      opacity: 0.5,
    },
  },
  marginRight10: {
    marginRight: "5px",
  },
  greyout: {
    color: "#9ab0bc",
  },
}));
