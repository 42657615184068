export function ProcUsersId(data: any) {
  var obj = {
    id: data.id,
    client_id: data.client_id,
    last_name: data.last_name,
    first_name: data.first_name,
    full_name: data.full_name,
    phone: data.phone,
    business_email: data.business_email,
    private_email: data.private_email,
    image_id: data.image_id,
    authorities: data.authorities,
    enable: data.enable,
    create_at: data.create_at,
    registration_status: data.registration_status,
    employee_id: data.employee_id
  };
  return obj;
}
